export default {
  items: [{
      title: true,
      name: 'Pages',
      class: '',
      wrapper: {
        element: '',
        attributes: {}
      }
    },
    {
      name: 'Home Page',
      icon: 'icon-home',
      children: [{
          name: 'Content Blocks',
          icon: 'fa fa-circle',
          url: '/ContentBlocks/Home',
        },
        {
          name: 'Home Page Links',
          icon: 'fa fa-circle',
          url: '/HomePageLinks',
        },
        {
          name: 'Promotion Banners',
          icon: 'fa fa-circle',
          url: '/PromotionBanners',
        },
        {
          name: 'Other Banners',
          icon: 'fa fa-circle',
          url: '/OtherBanners',
        },
        {
          name: 'Search Index',
          icon: 'fa fa-circle',
          url: '/SearchIndex',
        },
        // {
        //   name: 'Models',
        //   icon: 'fa fa-circle',
        //   url: '/VehicleModels',
        // },
      ]
    },

    {
      name: 'New Vehicles',
      icon: 'icon-list',
      children: [{
          name: 'Categories',
          icon: 'fa fa-circle',
          url: '/VehicleCategories',
        },
        {
          name: 'Models',
          icon: 'fa fa-circle',
          url: '/VehicleModels',
        },
        {
          name: 'Content Blocks',
          icon: 'fa fa-circle',
          url: '/VehicleContentBlocks',
        },
        {
          name: 'Vehicle Image Gallery',
          icon: 'fa fa-circle',
          url: '/VehicleGalleryImages',
        },
        // {
        //   name: 'Promo Banners',
        //   icon: 'fa fa-circle',
        //   url: '/PromotionBanners',
        // },
        {
          name: 'Vehicle Promo Banners',
          icon: 'fa fa-circle',
          url: '/VehiclePromotionBanners',
        },

        {
          name: 'Variants',
          icon: 'fa fa-circle',
          url: '/VehicleVariants',
        },
        {
          name: 'Videos',
          icon: 'fa fa-circle',
          url: '/VehicleVideos',
        },
        // {
        //   name: '360 Images',
        //   url: '/Vehicle360',
        // },
      ]
    },
    // {
    //   name: 'Commercial Vehicles',
    //   icon: 'icon-list',
    //   children: [
    //     {
    //       name: 'Models',
    //       icon: 'fa fa-circle',
    //       url: '/VehicleModels',
    //     },
    //     {
    //       name: 'Content Blocks',
    //       icon: 'fa fa-circle',
    //       url: '/VehicleContentBlocks',
    //     },
    //     {
    //       name: 'Promo Banners',
    //       icon: 'fa fa-circle',
    //       url: '/PromotionBanners',
    //     },
    //     {
    //       name: 'Variants',
    //       icon: 'fa fa-circle',
    //       url: '/VehicleVariants',
    //     },
    //     {
    //       name: 'Videos',
    //       icon: 'fa fa-circle',
    //       url: '/VehicleVideos',
    //     },
    //   ]
    // },
    {
      name: 'Owner Services',
      icon: 'icon-list',
      children: [{
        name: 'Content Blocks',
        icon: 'fa fa-circle',
        url: '/ContentBlocks/OwnerServices',
        },
        {
          name: 'Banners',
          icon: 'fa fa-circle',
          url: '/OwnerServicesBanners',
        },
        {
          name: 'Suggested Products',
          icon: 'fa fa-circle',
          url: '/SuggestedProducts',
        },
      ]
    },
    {
      name: 'Parts & Accessories',
      icon: 'icon-list',
      children: [{
        name: 'Content Blocks',
        icon: 'fa fa-circle',
        url: '/ContentBlocks/PartsAndAccessories',
        },
      ]
    },
    {
      name: 'Promotions',
      icon: 'icon-list',
      children: [
        {
          name: 'Promotions',
          icon: 'fa fa-circle',
          url: '/Promotions',
        },
        // {
        //   name: 'Promotion Content Blocks',
        //   icon: 'fa fa-circle',
        //   url: '/PromotionContentBlocks',
        // },
      ]
    },

    // {
    //   name: 'Book A Service',
    //   icon: 'icon-list',
    //   children: [
    //     {
    //       name: 'Models',
    //       icon: 'fa fa-circle',
    //       url: '/VehicleModels',
    //     },
    //     {
    //       name: 'Dealers',
    //       icon: 'fa fa-circle',
    //       url: '/Dealers',
    //     },
    //   ]
    // },

    // {
    //   name: 'Book A Test Drive',
    //   icon: 'icon-list',
    //   children: [
    //     {
    //       name: 'Models',
    //       icon: 'fa fa-circle',
    //       url: '/VehicleModels',
    //     },
    //     {
    //       name: 'Dealers',
    //       icon: 'fa fa-circle',
    //       url: '/Dealers',
    //     },
    //   ]
    // },

    {
      name: 'Fleet',
      icon: 'icon-list',
      children: [
        {
          name: 'Fleet Banners',
          icon: 'fa fa-circle',
          url: '/FleetBanners',
        },
      ]
    },

    {
      name: 'Corporate Sales',
      icon: 'icon-list',
      children: [
        {
          name: 'Content Blocks',
          icon: 'fa fa-circle',
          url: '/ContentBlocks/CorporateSales',
        },
        {
          name: 'Banners',
          icon: 'fa fa-circle',
          url: '/CorporateSalesPromotionBanners',
        },
        {
          name: 'Testimonials',
          icon: 'fa fa-circle',
          url: '/CorporateSalesTestimonials',
        },
      ]
    },


    // {
    //   name: 'Find a Dealer',
    //   icon: 'icon-list',
    //   children: [
    //     {
    //       name: 'Dealers',
    //       icon: 'fa fa-circle',
    //       url: '/Dealers',
    //     },
    //   ]
    // },

    {
      name: 'Pre Owned',
      icon: 'icon-list',
      children: [
        {
          name: 'Pre Owned Banners',
          icon: 'fa fa-circle',
          url: '/PreOwnedBanners',
        },
      ]
    },

    {
      name: 'Dealerships',
      icon: 'icon-list',
      children: [
        {
          name: 'Dealers',
          icon: 'fa fa-circle',
          url: '/Dealers',
        },
        {
          name: 'Dealer Team Members',
          icon: 'fa fa-circle',
          url: '/DealerTeamMembers',
        },
        {
          name: 'Dealer Promotions',
          icon: 'fa fa-circle',
          url: '/DealerPromotions',
        },
        {
          name: 'Dealer Events',
          icon: 'fa fa-circle',
          url: '/DealerEvents',
        },
      ]
    },
    {
      name: 'News Feed',
      icon: 'icon-list',
      children: [
        {
          name: 'News',
          icon: 'fa fa-circle',
          url: '/NewsFeed/News',
        },
        {
          name: 'Events',
          icon: 'fa fa-circle',
          url: '/NewsFeed/Events',
        },
        {
          name: 'Images',
          icon: 'fa fa-circle',
          url: '/NewsFeedGalleryItems',
        },
        {
          name: 'Promotion Banners',
          icon: 'fa fa-circle',
          url: '/NewsFeedPromotionBanners',
        },
      ]
    },
      {
      name: 'Page Seo',
      icon: 'icon-list',
      url: 'PageSeos'
    },

    {
      name: 'Bulk Uploads',
      icon: 'icon-list',
      children: [
        {
          name: 'Vehicle Price Update',
          icon: 'fa fa-circle',
          url: '/VehiclePriceUpdate',
        },
        {
          name: 'Accessories Upload',
          icon: 'fa fa-circle',
          url: '/AccessoriesUpload',
        },
        
      ]
    },
      {
        name: 'GlobalSettings',
        url: '/GlobalSettings',
        icon: 'icon-list',
      },
    // {
    //   name: 'PasswordResetTokens',
    //   url: '/PasswordResetTokens',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


    // {
    //   name: 'FloorVehicleAttributeStores',
    //   url: '/FloorVehicleAttributeStores',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


    // {
    //   name: 'CommercialVehicleCategories',
    //   url: '/CommercialVehicleCategories',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


    // {
    //   name: 'ContentBlocks',
    //   url: '/ContentBlocks',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


    // {
    //   name: 'VehicleCategories',
    //   url: '/VehicleCategories',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


    // {
    //   name: 'VehicleModels',
    //   url: '/VehicleModels',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


    // {
    //   name: 'Vehicles',
    //   url: '/Vehicles',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


    // {
    //   name: 'SecondHandVehicles',
    //   url: '/SecondHandVehicles',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


    // {
    //   name: 'HomePageLinks',
    //   url: '/HomePageLinks',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


    // {
    //   name: 'PromotionBanners',
    //   url: '/PromotionBanners',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


    // {
    //   name: 'FleetSections',
    //   url: '/FleetSections',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


    // {
    //   name: 'Enquiries',
    //   url: '/Enquiries',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


    // {
    //   name: 'ServiceBookings',
    //   url: '/ServiceBookings',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


    // {
    //   name: 'TestDriveBookings',
    //   url: '/TestDriveBookings',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


    // {
    //   name: 'Accessories',
    //   url: '/Accessories',
    //   icon: 'icon-list',
    //   badge: {
    //     variant: 'primary',
    //     text: ''
    //   }
    // },


  ]
};
